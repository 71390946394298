class ReactRedirectService {
    constructor(flagShipService, APIService, UserService) {
        this.flagShipService = flagShipService;
        this.apiService = APIService;
        this.userService = UserService;
        this.angularToReactData = {
            'product-select':{
                path: '/quote-flow/product-select',
                flagship: 'redirectToProductSelectPage',
                showReactNav: true,
            },
            'how-property-is-used':{
                path: '/quote-flow/product-select',
                flagship: 'redirectToProductSelectPage',
                showReactNav: false,
            },
            'home-quality':{
                path: '/quote-flow/product-select',
                flagship: 'redirectToProductSelectPage',
                showReactNav: false,
            },
            'your-address': {
                path: '/quote-flow/address',
                flagship: 'redirectToEnterAddressPage',
                showReactNav: false,
            },
            'your-home': {
                path: '/quote-flow/your-home',
                flagship: 'redirectToHomeInfoPage',
                showReactNav: true,
            },
            'prelim-discounts':{
                path: '/quote-flow/discounts',
                flagship: 'redirectToReactDiscount',
                showReactNav: true,
            },
            'discounts-1':{
                path: '/quote-flow/discounts',
                flagship: 'redirectToReactDiscount',
                showReactNav: false,
            },
            'about-yourself':{
                path: '/quote-flow/customer-info',
                flagship: 'redirectToPersonalInfoPage',
                showReactNav: true,
            },
            'prelim-quote':{
                path: '/quote-flow/prelim-quote',
                flagship: 'redirectToQuickQuotePage',
                showReactNav: true,
            },
            'home-details':{
                path: '/quote-flow/eligibility',
                flagship: 'redirectToEligibilityPage',
                showReactNav: true,
            },
            'claim-check': {
                path: '/quote-flow/claim-check',
                flagship: 'redirectToClaimCheckPage',
                showReactNav: true,
            },
            'claim-details':{
                path: '/quote-flow/claim-details',
                flagship: 'redirectToClaimCheckPage',
                showReactNav: false,
            },
            'no-claims':{
                path: '/quote-flow/no-claims',
                flagship: 'redirectToClaimCheckPage',
                showReactNav: false,
            },
            'final-quote':{
                path: '/quote-flow/final-quote',
                flagship: 'redirectToFinalQuotePage',
                showReactNav: true,
                reactIndex: 9,
            },
            'checkout':{
                path: '/quote-flow/checkout',
                flagship: 'redirectToCheckoutPage',
                showReactNav: true,
                reactIndex: 10,
            },
            'confirmation':{
                path: '/quote-flow/confirmation',
                flagship: 'redirectToConfirmationPage',
                showReactNav: false,
            },
            'organization-restriction' :{
                path: '/error/restriction',
                // just need to assign a flagship value that will redirect
                flagship: 'redirectToHomeInfoPage',
                showReactNav: false,
            }
        };
        this.reactRedirect =  false;
    }

    redirectToReactIfAllowed(url){
        const {leadId, page} = this.parseLeadUrl(url);
        const flagShipDataPromise = this.getFlagShipData();
        const applicationIdPromise = this.getApplicationId(leadId);
        Promise.all([flagShipDataPromise,applicationIdPromise]).then(([flagShipData,applicationId]) => {
            if (this.reactPageIsOn(flagShipData, page)){
                if (!leadId){
                    //If leadId is null then this will redirect to address page
                    this.redirectToPage(this.angularToReactData['your-address'].path,null);
                }
                this.redirectToPage(this.angularToReactData[page].path, applicationId.application_uuid, applicationId.address_uuid);
            }
        });
    }
    getFlagShipData(){
        const {
            FLAGSHIP_CAMPAIGN_ID,
        } = window.appConfig;
        const producerId = this.userService.getId() ? this.userService.getId() : '';
        const organizationId = this.userService.getOrgId() ? this.userService.getOrgId().toString() : '';
        const parentOrganizationId = this.userService.getRootOrgId() ? this.userService.getRootOrgId().toString() : '';
        const rootOrganizationId = this.userService.getParentOrgId() ? this.userService.getParentOrgId().toString() : '';
        return this.flagShipService.getCampaignData(
            { campaignID: FLAGSHIP_CAMPAIGN_ID, producerId, organizationId, parentOrganizationId, rootOrganizationId }
        );
    }

    parseLeadUrl(url) {
        // Example URL
        // http://localhost.com:3003/your-home?disableAnimation=true&producer_id=auth0%7C615ceab490f3a1006a66fc30&organization_id=1532&partner=__producer&leadId=68f059fd-f393-add8-4fbc-6fdc4639a4d3
        const newUrl = new URL(url);
        return {
            leadId: newUrl.searchParams.get('leadId'),
            page: newUrl.pathname.substring(1), // substring(1) remove the / in the pathname /your-home
        };
    };

    reactPageIsOn(flagshipData, page) {
        const data = flagshipData.data &&
            flagshipData.data.variation &&
            flagshipData.data.variation.modifications &&
            flagshipData.data.variation.modifications.value;
        // if we have no flagship data we just stay in react
        if (!data || !data.reactRedirect || !page){
            return false;
        }
        let isReactOn = false;
        Object.entries(data).forEach(([flagShipKey,flagShipValue], _index) => {
            if(this.angularToReactData[page].flagship === flagShipKey) {
                isReactOn = isReactOn || flagShipValue;
            }}
        );
        if (data.reactRedirect) {
            this.reactRedirect = true;
        }
        return isReactOn;
    }

    getLocationHostName() {
        return window.location.hostname;
    }

    isLocalHost() {
        return this.getLocationHostName().includes('localhost');
    }

    getApplicationId(leadId) {
        if (!leadId) {
            return new Promise(resolve => resolve(false));
        }
        return this.apiService.getApplicationId(leadId);
    }

    pageNotMigration(currentPage) {
        return ['checkout'].includes(currentPage);
    }

    redirectToPage(page, applicationId, addressId = undefined, currentPage) {
        // if null applicationID send to address page
        const baseRoute = applicationId != null ? page : this.angularToReactData["your-address"].path;
        const basePath = this.isLocalHost()
            ? `http://${this.getLocationHostName()}:4000${baseRoute}`
            : `${window.location.origin}/v2${baseRoute}`;
        const newUrl = new URL(basePath);
        const isErrorPage = page && page.includes('error');
        if(applicationId && !isErrorPage) {
            newUrl.searchParams.set('applicationId', applicationId);
        }
        if(addressId && !isErrorPage) {
            newUrl.searchParams.set('addressId', addressId);
        }
        // We have all flow migrated to react app, except the final quote page and
        // the checkout page. Therefore, we need to pass latestPage to react nav for
        // those 2 pages, to allow react app render the nav bar properly. 
        if(this.pageNotMigration(currentPage)) {
            newUrl.searchParams.set('latestPage', this.angularToReactData[currentPage]['reactIndex']);
        }
        newUrl.searchParams.set('orgId', this.userService.getOrgId());
        window.location.href = newUrl.toString();
    }

    navigationToReactPage(navPageKey, applicationId, addressId = undefined, currentPage) {

        this.getFlagShipData().then((flagshipData) => {
            if (this.reactPageIsOn(flagshipData, navPageKey)){
                for (const [reactPageKey, reactPageValue] of Object.entries(this.angularToReactData)) {
                    if (reactPageKey === navPageKey) {
                        this.redirectToPage(reactPageValue.path, applicationId, addressId, currentPage);
                    }
                }
            }
        });
    }

    showNavPage(navPageKey, shouldShowNav) {
        if(this.reactRedirect) {
            for (const [reactPageKey, reactPageValue] of Object.entries(this.angularToReactData)) {
                if (reactPageKey === navPageKey && reactPageValue.showReactNav) {
                    return shouldShowNav;
                }
            }
            return false;
        }
        return shouldShowNav;
    }
}

window.angular
    .module('MyHippoProducer.Services')
    .service('ReactRedirectService', function ( FlagshipService, APIService, UserService) {
        'ngInject';
        return new ReactRedirectService( FlagshipService, APIService, UserService);
    });
